<template>
  <div>
    <div v-show="!(dialogVisible || dialogVisible2)">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        status-icon
        :rules="rules"
        class="flex flex-1 "
      >
        <div class="w-full">
          <el-form-item label="账号">
            <span>{{ ruleForm.account }}</span>
          </el-form-item>
          <el-form-item label="姓名" required>
            <el-input
              v-model="ruleForm.name"
              type="text"
              maxlength="50"
              placeholder="最多50个字"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <div class=" flex justify-between">
              <span>{{ ruleForm.phone }}</span>
              <span
                class=" text-warning cursor-pointer"
                @click="dialogVisible2 = true"
                >变更</span
              >
            </div>
          </el-form-item>
          <el-form-item label="所在地区" required>
            <SelectArea
              style="width: 100%"
              :areaId="ruleForm.areaId"
              @change="areaChange"
            />
          </el-form-item>
          <el-form-item label="微信" required>
            <div class=" flex justify-between" v-if="ruleForm.wechatBinded">
              <span>{{ ruleForm.wechatNickname }}</span>
              <span>解绑</span>
            </div>
            <div class=" flex justify-between" v-else>
              <span>未绑定</span>
              <span
                class=" text-warning cursor-pointer"
                @click="showBindBarcode"
                >绑定</span
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class=" flex justify-end space-x-16 border-t pt-8">
        <div
          class="border px-16 py-2 rounded-full border-gray-900  cursor-pointer"
          @click="cancle"
        >
          取消
        </div>
        <div
          class="border px-16 py-2 rounded-full border-warning bg-warning text-white cursor-pointer"
          @click="updateProfile"
        >
          确定
        </div>
      </div>
    </div>
    <el-dialog
      title="微信绑定"
      :visible.sync="dialogVisible"
      :width="'35%'"
      modal-append-to-body
      append-to-body
    >
      <img :src="barCodeUrl" alt="" />
      <p class=" text-center">请使用微信扫描二维码进行授权绑定</p>
    </el-dialog>
    <el-dialog
      title="变更手机号"
      :visible.sync="dialogVisible2"
      :width="'35%'"
      modal-append-to-body
      append-to-body
    >
      <ChangePhone @submit="init"/>
    </el-dialog>
  </div>
</template>

<script>
import SelectArea from "@/components/selectArea/SelectArea.vue";
import ChangePhone from "./ChangePhone.vue";
import { profileApi, updateProfileApi } from "@/api/login";

export default {
  components: {
    SelectArea,
    ChangePhone,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      barCodeUrl: "",
      imgList: [],
      ruleForm: {
        name: "",
        phone: "",
        areaId: "",
        wechatNickname: "",
        wechatBinded: false,
      },
      rules: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      profileApi().then((res) => {
        this.ruleForm = { ...res };
      });
    },
    areaChange(dataObj) {
      this.ruleForm.areaId = dataObj.areaId;
      // this.ruleForm.provinceId = dataObj.areaIds[0];
      // this.ruleForm.cityId = dataObj.areaIds[1];
      // this.ruleForm.countyId = dataObj.areaIds[2];
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    getFileList(fileList) {
      this.imgList = fileList;
      this.ruleForm.imgList = fileList;
    },
    showBindBarcode() {
      this.dialogVisible = true;
    },
    updateProfile() {
      const { name, areaId } = this.ruleForm;
      updateProfileApi({
        name,
        areaId,
      }).then(() => {
        this.$emit("refresh");
        this.$message({
          type: "success",
          message: "更新成功!",
        });
        this.$emit("cancle");
      });
    },
    cancle(){
      this.$emit("refresh");
      this.$emit("cancle");
    }
  },
};
</script>
