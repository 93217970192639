<template>
  <div>
    <div class="flex justify-between">
      <el-avatar
        :size="'large'"
        :src="
          'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'
        "
      ></el-avatar>
      <div class="flex-1 pl-16 space-y-8">
        <div class="flex justify-between  border-b-2">
          <div>
            <div class=" space-x-16">
              <span>{{ userName }}</span>
              <span>{{ account }}</span>
              <span>{{ phone }}</span>
            </div>
            <div class=" text-gray-900 mb-16">{{ areaName }}</div>
            <!-- <div class="space-x-16 pb-8" v-if="wechatBinded">
              <span>微信</span>
              <span>{{ wechatNickname }}</span>
              <span class="text-warning">解绑</span>
            </div>
            <div class="space-x-16 pb-8" v-else>
              <span>微信</span>
              <span>未绑定</span>
              <span class="text-warning cursor-pointer" @click="showBarcode"
                >绑定</span
              >
            </div> -->
          </div>
          <div class=" w-165 space-x-8 text-warning">
            <span class="cursor-pointer" @click="showDialog">修改账号资料</span>
            <span class="cursor-pointer" @click="showDialog2">修改密码</span>
          </div>
        </div>

        <div class="space-x-16" v-if="hasCompany">
          <!-- <span><i class="iconfont icon-fill_ac text-warning"></i></span> -->
          <span>{{ companyName }}</span>
          <span
            class="text-warning cursor-pointer"
            @click="$router.replace('/company/companyInfo')"
            >查看公司资料</span
          >
        </div>
        <div class="space-x-16" v-else>
          <!-- <span><i class="iconfont icon-fill_ac"></i></span> -->
          <span class="text-gray-900">未认证</span>
          <span class="text-warning cursor-pointer" @click="auth">前往认证</span>
        </div>
      </div>
    </div>
    <!-- 修改信息 -->
    <el-dialog
      title="编辑资料"
      :visible.sync="dialogVisible"
      :width="'35%'"
      :close-on-click-modal="false"
      append-to-body
      @close="init"
    >
      <EdieUserInfo
        v-if="dialogVisible"
        @cancle="dialogVisible = false"
        @refresh="init"
      />
    </el-dialog>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      :width="'35%'"
    >
      <ChangePasswordVue @cancle="dialogVisible2 = false" />
    </el-dialog>
    <!-- 绑定微信 -->
    <el-dialog
      title="微信绑定"
      :visible.sync="dialogVisible3"
      :width="'35%'"
      append-to-body
    >
      <img :src="barCodeUrl" alt="" />
      <p class=" text-center">请使用微信扫描二维码进行授权绑定</p>
    </el-dialog>
  </div>
</template>
<script>
import EdieUserInfo from "./components/EditUserInfo.vue";
import ChangePasswordVue from "./components/ChangePassword.vue";
import { redirectUrl } from "envConfigPath";
import { profileApi, codeUrl } from "@/api/login";
import { STATUS } from "@/store/index";

export default {
  components: {
    EdieUserInfo,
    ChangePasswordVue,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      barCodeUrl: "",
      phone: "",
      userName: "",
      account: "",
      areaName: "",
      wechatNickname: "",
      wechatAvatar: "",
      wechatBinded: false,
      hasCompany: false,
      companyName: "",
      companyAuthed: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      profileApi().then((res) => {
        this.$store.dispatch("setProfile", res);
        const {
          phone,
          account,
          name,
          areaName,
          wechatNickname,
          wechatAvatar,
          wechatBinded,
          hasCompany,
          companyName,
          companyAuthed,
        } = res;
        this.phone = phone;
        this.account = account;
        this.userName = name;
        this.areaName = areaName;
        this.wechatNickname = wechatNickname;
        this.wechatAvatar = wechatAvatar;
        this.wechatBinded = wechatBinded;
        this.hasCompany = hasCompany;
        this.companyName = companyName;
        this.companyAuthed = companyAuthed;
      });
    },
    showDialog() {
      this.dialogVisible = true;
    },
    showDialog2() {
      this.dialogVisible2 = true;
    },
    showBarcode() {
      codeUrl({
        online: true,
        redirectUrl: encodeURIComponent(redirectUrl),
      }).then((res) => {
        this.barCodeUrl = res;
        console.log(this.barCodeUrl);
      });
      this.dialogVisible3 = true;
    },
    auth() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
  },
};
</script>
