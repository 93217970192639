<template>
  <el-cascader
    style="width:100%;"
    :size="size"
    v-model="areaObj.areaIds"
    :placeholder="placeholder"
    :disabled="disabled"
    clearable
    :options="addressOptions"
    :props="{ checkStrictly: checkStrictly }"
    :popper-class="popperClass"
    @change="handleChange"
    ><template #prefix> <i class="iconfont icon-login_verify"></i> </template
  ></el-cascader>
</template>

<script>
import { getAddressList, getPositionAreaById } from "@/utils/splicingData";
export default {
  name: "selectArea",
  data() {
    return {
      addressOptions: [],
      areaObj: {
        areaId: 0,
        areaIds: [],
        areaName: "",
      },
    };
  },
  props: {
    //是否允许选择任意一级选项
    checkStrictly: {
      type: [Boolean],
      default: false,
    },
    size: {
      type: [String],
      default: "medium",
    },
    placeholder: {
      type: [String],
      default: "请选择所在区域",
    },
    areaData: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    areaId: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: [Boolean],
      default: false,
    },
    popperClass: {
      type: String,
      default: "el-cascader-box",
    },
  },
  watch: {
    areaData: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.areaObj.areaIds = val;
        } else {
          this.areaObj.areaIds = [];
        }
      }
    },
    areaId: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.renderArea(val);
          }, 200);
        }
      },
      immediate: true,
    },
  },
  created() {
    //获取地址列表
    this.addressOptions = getAddressList();
  },
  methods: {
    //回显地址
    renderArea(val) {
      if (this.addressOptions.length) {
        let areaObj = getPositionAreaById(val, this.addressOptions);
        this.areaObj.areaIds = areaObj.position;
      } else {
        setTimeout(() => {
          this.renderArea(val);
        }, 200);
      }
    },
    handleChange(val) {
      this.areaObj.areaId = val[val.length - 1];
      let areaObj = getPositionAreaById(
        val[val.length - 1],
        this.addressOptions
      );
      this.areaObj.areaName = areaObj.name;
      this.$emit("change", this.areaObj);
    },
  },
};
</script>

<style lang="less">
// 区域最后一级点击文字可选择
.el-cascader-box .el-cascader-menu:nth-child(3) .el-cascader-node {
  overflow: hidden !important;
}

.el-cascader-box .el-cascader-menu:nth-child(3) .el-cascader-node .el-radio {
  width: 100% !important;
  height: 100% !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  position: absolute !important;
}

.el-cascader-box
  .el-cascader-menu:nth-child(3)
  .el-cascader-node
  .el-cascader-node__label {
  margin-left: 14px !important;
}
</style>
