<template>
  <div>
    <div
      v-show="step === 1 || step === 2"
      class="text-xl  flex w-full m-auto justify-center text-center mb-s-s space-x-8"
    >
      <div class="flex items-center">
        <span
          class=" text-sm rounded-full border border-black-200 inline-block  w-16 h-16 mr-8"
          :class="{
            'bg-warning text-white border-warning': step === 1,
            'text-black-100': step === 2,
          }"
          style="line-height: 16px;"
          >1</span
        >
        <span
          :class="{
            'text-warning': step === 1,
          }"
          >身份验证</span
        >
      </div>
      <i class="iconfont icon-line_arrowright text-warning text-xl"></i>
      <div class="flex items-center">
        <span
          class=" text-sm rounded-full inline-block border border-black-200  w-16 h-16 mr-8"
          :class="{
            'bg-warning text-white border-warning': step === 2,
            'text-black-100': step === 1,
          }"
          style="line-height: 16px;"
          >2</span
        >
        <span
          :class="{
            'text-warning': step === 2,
          }"
          >新手机号验证</span
        >
      </div>
    </div>
    <el-form
      v-show="step === 1"
      ref="ruleFormRef"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm.phone"
          maxlength="11"
          class="noborder"
          type="text"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm.smsCaptcha"
          type="text"
          class="noborder"
          maxlength="6"
          placeholder="请输入验证码"
          ><template #append>
            <MsgCode :mobile="ruleForm.phone" :type="'checkPhone'" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item class="w-full">
        <div
          class=" text-white bg-warning rounded text-center"
          @click="goToStep2"
        >
          下一步
        </div>
      </el-form-item>
    </el-form>
    <el-form
      v-show="step === 2"
      ref="ruleFormRef2"
      :model="ruleForm2"
      status-icon
      :rules="rules"
      label-width="0px"
      class="mb-s-xs"
    >
      <el-form-item prop="phone" class="w-full">
        <el-input
          v-model="ruleForm2.phone"
          maxlength="11"
          class="noborder"
          type="text"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="smsCaptcha">
        <el-input
          v-model="ruleForm2.smsCaptcha"
          type="text"
          class="noborder"
          maxlength="6"
          placeholder="请输入验证码"
          ><template #append>
            <MsgCode :mobile="ruleForm2.phone" :type="'changePhone'" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item class="w-full" prop="field3">
        <div
          class=" text-white bg-warning rounded text-center cursor-pointer"
          @click="submitForm"
        >
          确定
        </div>
      </el-form-item>
    </el-form>
    <div v-show="step === 3" class="step3">
      <img
        src="@/assets/register/changePhoneSuc.png"
        class="mx-auto mb-16"
        alt=""
      />
      <p class="text-center">手机号变更成功</p>
      <p class="text-center">您的手机号已经变更成功，下次请用手机号登录。</p>
    </div>
  </div>
</template>

<script>
import { checkAccountsApi, changePhoneApi } from "@/api/login";
import { mobileValidate, captchaValidate } from "@/utils/validate";
import MsgCode from "@/components/msgCode/msgCode";
export default {
  components: {
    MsgCode,
  },
  data() {
    return {
      step: 1,
      ruleForm: {
        phone: "",
        smsCaptcha: "",
      },
      ruleForm2: {
        phone: "",
        smsCaptcha: "",
      },
      rules: {
        phone: [{ validator: mobileValidate, trigger: "blur" }],
        smsCaptcha: [{ validator: captchaValidate, trigger: "blur" }],
      },
    };
  },
  methods: {
    areaChange(dataObj) {
      this.ruleForm.provinceId = dataObj.areaIds[0];
      this.ruleForm.cityId = dataObj.areaIds[1];
      this.ruleForm.countyId = dataObj.areaIds[2];
    },
    changeStatus(newStatus) {
      this.$store.dispatch("changeAuthStatus", newStatus);
    },
    goToStep2() {
      // 验证
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          checkAccountsApi(this.ruleForm).then(() => {
            this.step = 2;
          });
        } else {
          return false;
        }
      });
    },
    submitForm() {
      // 验证
      this.$refs.ruleFormRef.validate((valid) => {
        if (valid) {
          changePhoneApi(this.ruleForm2).then(() => {
            this.step = 3;
            this.$emit("submit");
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.step3 {
  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
}
</style>
